<template>
  <div class="page">
    <iframe v-if="url" :src="url" ref="iframe"></iframe>
  </div>
</template>

<script>

export default {
  components: {},
  props: [],
  name: 'extern',
  data() {
    return {
      canOpenMenu: false,
      url: null,
    };
  },
  created() {
    this.$http.get('link/' + this.$route.params.linkId).then((response) => {
      if (response.data.data.should_open_in_new_window) {
        window.open(response.data.data.url, '_blank');
        this.$router.go(-1);
      }
      this.url = response.data.data.url;
    });
    /**
     * To listen for this you'll need the following code:

     window.addEventListener('message', function(event) {
                if(typeof event.data === 'object' && typeof event.data.type === 'string' && event.data.type === 'openMenu' ) {
                    alert('open menu here');
                }
             });

     */
    window.addEventListener('openMenu', () => {
      this.$refs.iframe.contentWindow.postMessage({ type: 'openMenu' }, '*');
    }, false);
  },
  methods: {},
};
</script>

<style>
iframe {
  border:     none;
  position:   absolute;
  left:       0px;
  right:      0px;
  bottom:     0px;
  min-width:  100%;
  min-height: 100%;
}
</style>
